@charset "utf-8";
html,
body {
  margin: 0;
  height: 100%;
}

div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li,
blockquote,
pre,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
section,
summary,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

select,
input,
button {
  margin: 0;
  border: 0;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 2.0em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.3em;
}

h6 {
  font-size: 1.2em;
}

p {
  font-size: 1.0em;
}
