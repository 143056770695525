@import "~@angular/material/prebuilt-themes/indigo-pink.css"
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap')

html, body
    height: 100%
    margin: 0
    padding: 0

html::-webkit-scrollbar
    width: 0px
    height: 0px
    background: transparent
