@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?3ip4lp');
    src: url('../fonts/icomoon.eot?3ip4lp#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?3ip4lp') format('truetype'),
    url('../fonts/icomoon.woff?3ip4lp') format('woff'),
    url('../fonts/icomoon.svg?3ip4lp#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icomoon-"],
[class*=" icomoon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icomoon-findmine:before {
	content: "\e903";
}

.icomoon-calendar:before {
	content: "\e900";
}

.icomoon-export:before {
	content: "\e901";
}

.icomoon-next:before {
	content: "\e902";
}
